import React from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import useStore from '../store';

const Header = () => {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const clearUid = useStore((state) => state.clearUid);

  const handleLogout = () => {
    if (window.confirm('ログアウトしますか？')) {
      signOut(auth).then(() => {
        clearUid();
        navigate('/login');
      }).catch((error) => {
        console.error('ログアウトに失敗しました', error);
      });
    }
  };

  return (
    <header className="header">
      <h1>CONOTY ADMIN</h1>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/notification">Notification</Link></li>
          <li><Link to="/update-note">Update Note</Link></li>
          <li><Link to="/feedback">Feedback</Link></li>
          <li><Link to="/manual">Manual</Link></li>
          {user && (
            <li><button onClick={handleLogout}>Log Out</button></li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default Header;