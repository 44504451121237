import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import '../App.css';

const UpdateNotePreview = ({ updateNoteId }) => {
  const [titleJa, setTitleJa] = useState('');
  const [contentJa, setContentJa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [devices, setDevices] = useState({
    iOS: false,
    Android: false,
    WEB: false
  });

  useEffect(() => {
    const fetchUpdateNote = async () => {
      if (updateNoteId) {
        try {
          const docRef = doc(db, 'publish_update_notes', updateNoteId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setTitleJa(data.title_ja);
            setContentJa(data.content_ja);
            setTitleEn(data.title_en);
            setContentEn(data.content_en);
            // デバイス情報を取得
            setDevices(data.devices || {
              iOS: false,
              Android: false,
              WEB: false
            });
          }
        } catch (error) {
          console.error('アップデートノートの取得中にエラーが発生しました:', error);
        }
      }
    };

    fetchUpdateNote();
  }, [updateNoteId]);

  const previewStyle = {
    width: '375px', 
    height: '667px', 
    border: '1px solid #ccc', 
    overflow: 'auto', 
    padding: '10px',
    backgroundColor: 'white',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)'
  };

  // デバイスタグのレンダリング
  const renderDeviceTags = () => {
    return (
      <div style={{ marginBottom: '20px' }}>
        <h3>デバイスタグ</h3>
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          {Object.entries(devices).map(([device, isSelected]) => {
            if (isSelected) {
              return (
                <span
                  key={device}
                  style={{
                    backgroundColor: '#D6BDA5',
                    color: 'white',
                    padding: '4px 12px',
                    borderRadius: '16px',
                    fontSize: '14px',
                    display: 'inline-block'
                  }}
                >
                  {device}
                </span>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex' }}>
      {/* 日本語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleJa}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentJa }} />
        </div>
      </div>
      {/* 英語プレビュー */}
      <div style={{ width: '33%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
        <div style={previewStyle}>
          <h3>{titleEn}</h3>
          <div dangerouslySetInnerHTML={{ __html: contentEn }} />
        </div>
      </div>
      {/* 右エリア（デバイスタグ表示） */}
      <div style={{ width: '33%', padding: '20px' }}>
        {renderDeviceTags()}
      </div>
    </div>
  );
};

UpdateNotePreview.propTypes = {
  updateNoteId: PropTypes.string.isRequired,
};

export default UpdateNotePreview;