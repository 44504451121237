import React, { useState } from 'react';
import { auth, fetchUserData } from '../firebase';
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import useStore from '../store';

// スタイルをJavaScriptオブジェクトとして定義
const styles = {
  login: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
  },
  loginContainer: {
    backgroundColor: 'white',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.1)',
    width: '350px',
    textAlign: 'center',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#333',
  },
  inputGroup: {
    marginBottom: '20px',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    fontSize: '14px',
    boxSizing: 'border-box',
  },
  loginButton: {
    width: '100%',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
    backgroundColor: '#6C6059',
    color: 'white',
  },
  googleButton: {
    width: '100%',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
    backgroundColor: '#D58A6A',
    color: 'white',
  },
  appleButton: {
    width: '100%',
    padding: '10px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
    backgroundColor: '#000000',
    color: 'white',
  },
  errorMessage: {
    color: 'red',
    marginBottom: '15px',
  },
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const setUid = useStore((state) => state.setUid);
  const setUser = useStore((state) => state.setUser);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await processLogin(userCredential.user);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    setError('');
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      await processLogin(userCredential.user);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleAppleLogin = async () => {
    setError('');
    try {
      const provider = new OAuthProvider('apple.com');
      // Optionally, you can set custom scopes or parameters here
      const userCredential = await signInWithPopup(auth, provider);
      await processLogin(userCredential.user);
    } catch (error) {
      setError(error.message);
    }
  };

  const processLogin = async (user) => {
    const uid = user.uid;
    const userData = await fetchUserData(uid);

    if (userData.account_status !== 0) {
      setError('アクセス権限がありません。');
      return;
    }

    setUid(uid);
    setUser(userData);
    navigate('/home');
  };

  return (
    <div style={styles.login}>
      <div style={styles.loginContainer}>
        <h1 style={styles.heading}>CONOTY ADMIN<br/>Login</h1>
        {error && <p style={styles.errorMessage}>{error}</p>}
        <form onSubmit={handleLogin}>
          <div style={styles.inputGroup}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.loginButton}>Login with Email</button>
        </form>
        <button onClick={handleGoogleLogin} style={styles.googleButton}>Login with Google</button>
        <button onClick={handleAppleLogin} style={styles.appleButton}>Login with Apple</button>
      </div>
    </div>
  );
};

export default Login;
