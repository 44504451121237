import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../App.css';
import { storage, db } from '../firebase';
import ImageResize from 'quill-image-resize-module-react';
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, addDoc, doc, getDoc, setDoc, query, getDocs } from "firebase/firestore";
import useStore from '../store';

ReactQuill.Quill.register('modules/imageResize', ImageResize);

const UpdateNoteForm = ({ onCancel, onSaveDraft, updateNoteId }) => {
  const [titleJa, setTitleJa] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [contentJa, setContentJa] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [id, setId] = useState(updateNoteId || null);
  const [savedImages, setSavedImages] = useState({});
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [selectedTab, setSelectedTab] = useState('ja');
  const [, setIsTranslating] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState({
    iOS: false,
    Android: false,
    WEB: false
  });

  const { uid, user } = useStore((state) => ({
    uid: state.uid,
    user: state.user,
  }));

  useEffect(() => {
    const fetchUpdateNote = async () => {
      if (updateNoteId) {
        try {
          const docRef = doc(db, 'update_notes', updateNoteId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setId(updateNoteId);
            const data = docSnap.data();
            setTitleJa(data.title_ja);
            setTitleEn(data.title_en);
            setContentJa(data.content_ja);
            setContentEn(data.content_en);
            setSelectedDevices(data.devices || {
              iOS: false,
              Android: false,
              WEB: false
            });

            const imgElements = new DOMParser().parseFromString(data.content_ja + data.content_en, 'text/html').getElementsByTagName('img');
            const images = {};
            for (let img of imgElements) {
              if (img.src.startsWith('https://firebasestorage.googleapis.com')) {
                images[img.src] = img.src;
              }
            }
            setSavedImages(images);
          }
        } catch (error) {
          console.error('アップデートノートの取得中にエラーが発生しました:', error);
        }
      }
    };

    fetchUpdateNote();
    fetchComments();
  }, [updateNoteId]);

  const fetchComments = async () => {
    if (updateNoteId) {
      try {
        const commentsCollectionRef = collection(db, 'update_notes', updateNoteId, 'comments');
        const commentsQuery = query(commentsCollectionRef);
        const querySnapshot = await getDocs(commentsQuery);
        const fetchedComments = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(fetchedComments);
      } catch (error) {
        console.error('コメントの取得中にエラーが発生しました:', error);
      }
    }
  };

  const handleAddComment = async () => {
    if (newComment.trim() === '') return;

    try {
      const commentsCollectionRef = collection(db, 'update_notes', updateNoteId, 'comments');
      await addDoc(commentsCollectionRef, {
        text: newComment,
        createdAt: new Date(),
      });
      setNewComment('');
      fetchComments();
    } catch (error) {
      console.error('コメントの追加中にエラーが発生しました:', error);
    }
  };

  const collectTextNodes = (node, textNodes = []) => {
    for (let child of node.childNodes) {
      if (child.nodeType === Node.TEXT_NODE) {
        if (child.textContent.trim()) {
          textNodes.push(child);
        }
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        collectTextNodes(child, textNodes);
      }
    }
    return textNodes;
  };

  const translateHTMLContent = async (htmlContent, sourceLang, targetLang) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const textNodes = collectTextNodes(doc.body);
    const textsToTranslate = textNodes.map(node => node.textContent);

    const translatedTexts = [];
    for (let text of textsToTranslate) {
      const translated = await translateText(text, sourceLang, targetLang);
      translatedTexts.push(translated);
    }

    textNodes.forEach((node, index) => {
      node.textContent = translatedTexts[index];
    });

    return doc.body.innerHTML;
  };

  const translateText = async (text, sourceLang, targetLang) => {
    const functionUrl = 'https://us-central1-conoty-a33b9.cloudfunctions.net/openai_request';

    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        prompt: `Translate the following ${sourceLang} text to ${targetLang}: "${text}"`
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`翻訳リクエストが失敗しました: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    return data.response.trim();
  };

  const handleTranslationCopy = async () => {
    setIsTranslating(true);
    try {
      const sourceLanguage = 'Japanese';
      const targetLanguage = 'English';
      const titleToTranslate = titleJa;
      const contentToTranslate = contentJa;

      const titleResponse = await fetch('https://us-central1-conoty-a33b9.cloudfunctions.net/openai_request', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt: `Translate the following ${sourceLanguage} title to ${targetLanguage}: "${titleToTranslate}"`
        }),
      });

      if (!titleResponse.ok) {
        const errorText = await titleResponse.text();
        throw new Error(`タイトルの翻訳リクエストが失敗しました: ${titleResponse.status} ${errorText}`);
      }

      const titleData = await titleResponse.json();
      const translatedTitle = titleData.response.trim();

      const translatedContent = await translateHTMLContent(contentToTranslate, sourceLanguage, targetLanguage);

      setTitleEn(translatedTitle);
      setContentEn(translatedContent);

      alert('翻訳が完了しました');
    } catch (error) {
      console.error('翻訳中にエラーが発生しました:', error);
      alert(`翻訳中にエラーが発生しました: ${error.message}`);
    }
    setIsTranslating(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirmSubmit = window.confirm('申請しますか？');
    if (confirmSubmit) {
      try {
        await updateUpdateNoteStatus();
        alert('申請が完了しました。');
      } catch (error) {
        console.error('申請中にエラーが発生しました:', error);
        alert('申請中にエラーが発生しました。もう一度お試しください。');
      }
    }
  };

  const updateUpdateNoteStatus = async () => {
    try {
      let docRef;
      const updateNoteData = {
        title_ja: titleJa,
        title_en: titleEn,
        content_ja: contentJa,
        content_en: contentEn,
        updatedAt: new Date(),
        status: 'awaiting_approval',
        userId: uid,
        userName: user.name,
        devices: selectedDevices
      };

      if (id) {
        docRef = doc(db, 'update_notes', id);
        await setDoc(docRef, updateNoteData, { merge: true });
      } else {
        updateNoteData.createdAt = new Date();
        docRef = await addDoc(collection(db, 'update_notes'), updateNoteData);
        setId(docRef.id);
      }
      console.log('アップデートノートが正常に更新/作成されました。ID:', docRef.id);
    } catch (error) {
      console.error('Firestoreの更新/作成中にエラーが発生しました:', error);
      throw error;
    }
  };

  const handleSaveDraft = async () => {
    try {
      await cleanupUnusedImages();
      const processedContentJa = await processImagesInContent(contentJa);
      const processedContentEn = await processImagesInContent(contentEn);

      if (onSaveDraft) {
        onSaveDraft({ title_ja: titleJa, title_en: titleEn, content_ja: processedContentJa, content_en: processedContentEn });
      }

      await saveDraftToFirestore(titleJa, titleEn, processedContentJa, processedContentEn);

      alert('登録完了しました');
      console.log('下書きが保存されました');
    } catch (error) {
      console.error('下書きの保存中にエラーが発生しました:', error);
    }
  };

  const cleanupUnusedImages = async () => {
    const newContentImages = new DOMParser().parseFromString(contentJa + contentEn, 'text/html').getElementsByTagName('img');
    const newImageURLs = new Set();

    for (let img of newContentImages) {
      if (img.src.startsWith('https://firebasestorage.googleapis.com')) {
        newImageURLs.add(img.src);
      }
    }

    for (const oldImageUrl of Object.keys(savedImages)) {
      if (!newImageURLs.has(oldImageUrl)) {
        try {
          const storageRef = ref(storage, oldImageUrl);
          await deleteObject(storageRef);
          console.log('不要な画像が削除されました:', oldImageUrl);
        } catch (error) {
          console.error('不要な画像の削除中にエラーが発生しました:', error);
        }
      }
    }

    const updatedImages = {};
    newImageURLs.forEach(url => {
      updatedImages[url] = url;
    });
    setSavedImages(updatedImages);
  };

  const processImagesInContent = async (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const imgElements = tempDiv.getElementsByTagName('img');

    for (let img of imgElements) {
      if (img.src.startsWith('data:image')) {
        try {
          const imageUrl = await uploadImageToStorage(img.src);
          img.src = imageUrl;
          setSavedImages((prevImages) => ({ ...prevImages, [imageUrl]: imageUrl }));
        } catch (error) {
          console.error('画像のアップロード中にエラーが発生しました:', error);
        }
      }
    }

    return tempDiv.innerHTML;
  };

  const uploadImageToStorage = async (base64Image) => {
    try {
      const response = await fetch(base64Image);
      const blob = await response.blob();

      const uniqueFileName = `update_notes/${id || Date.now()}-${Math.random().toString(36).substring(2, 15)}.png`;
      const storageRef = ref(storage, uniqueFileName);

      await uploadBytes(storageRef, blob);
      return getDownloadURL(storageRef);
    } catch (error) {
      console.error('画像のアップロード中にエラーが発生しました:', error);
      throw error;
    }
  };

  const saveDraftToFirestore = async (titleJa, titleEn, contentJa, contentEn) => {
    try {
      const draftData = {
        title_ja: titleJa,
        title_en: titleEn,
        content_ja: contentJa,
        content_en: contentEn,
        updatedAt: new Date(),
        status: 'draft',
        userId: uid,
        userName: user.name,
        devices: selectedDevices // Add selected devices to the data
      };

      if (id) {
        const docRef = doc(db, 'update_notes', id);
        await setDoc(docRef, draftData);
      } else {
        draftData.createdAt = new Date();
        const docRef = await addDoc(collection(db, 'update_notes'), draftData);
        setId(docRef.id);
      }
    } catch (error) {
      console.error('Firestoreへの保存中にエラーが発生しました:', error);
      throw error;
    }
  };

  const handleDeviceChange = (device) => {
    setSelectedDevices(prev => ({
      ...prev,
      [device]: !prev[device]
    }));
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
    imageResize: {
      parchment: ReactQuill.Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar']
    }
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image'
  ];

  const PreviewComponent = () => (
    <div style={{ 
      width: '375px', 
      height: '667px', 
      border: '1px solid #ccc', 
      overflow: 'auto', 
      padding: '10px',
      backgroundColor: 'white',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)'
    }}>
      <h3>{selectedTab === 'ja' ? titleJa : titleEn}</h3>
      <div dangerouslySetInnerHTML={{ __html: selectedTab === 'ja' ? contentJa : contentEn }} />
    </div>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ width: '33%', padding: '20px' }}>
        <form onSubmit={handleSubmit} className="login" style={{ width: '100%' }}>
          <h2 className="feedback-title">新しいアップデートノートを作成</h2>
          <p>ID: {id ? id : '新規'}</p>

          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <button 
              type="button" 
              onClick={() => setSelectedTab('ja')} 
              style={{ 
                marginRight: '10px', 
                padding: '10px 20px', 
                backgroundColor: selectedTab === 'ja' ? '#D6BDA5' : '#f0f0f0', 
                color: selectedTab === 'ja' ? 'white' : 'black', 
                border: 'none',
                cursor: 'pointer' 
              }}>
              日本語
            </button>
            <button 
              type="button" 
              onClick={() => setSelectedTab('en')} 
              style={{ 
                padding: '10px 20px', 
                backgroundColor: selectedTab === 'en' ? '#D6BDA5' : '#f0f0f0', 
                color: selectedTab === 'en' ? 'white' : 'black', 
                border: 'none',
                cursor: 'pointer' 
              }}>
              英語
            </button>
          </div>

          <div>
            <label htmlFor="title">タイトル ({selectedTab === 'ja' ? '日本語' : '英語'})</label>
            <input
              id="title"
              type="text"
              value={selectedTab === 'ja' ? titleJa : titleEn}
              onChange={(e) => selectedTab === 'ja' ? setTitleJa(e.target.value) : setTitleEn(e.target.value)}
              placeholder="アップデートノートのタイトルを入力"
              style={{ width: '96%' }}
            />
          </div>

          <div>
            <label htmlFor="content">内容 ({selectedTab === 'ja' ? '日本語' : '英語'})</label>
            <ReactQuill
              key={selectedTab}
              value={selectedTab === 'ja' ? contentJa : contentEn}
              onChange={selectedTab === 'ja' ? setContentJa : setContentEn}
              modules={modules}
              formats={formats}
              style={{ height: '320px', marginBottom: '96px' }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '48px' }}>
            <button type="button" onClick={onCancel} className="google-button" style={{ width: '30%' }}>
              閉じる
            </button>
            <button type="button" onClick={handleTranslationCopy} className="login-button" style={{ width: '30%' }}>
              翻訳コピー
            </button>
            <button type="button" onClick={handleSaveDraft} className="login-button" style={{ width: '30%' }}>
              下書き保存
            </button>
          </div>
        </form>
      </div>

      <div style={{ width: '33%', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PreviewComponent />
        <button onClick={handleSubmit} className="login-button" style={{ width: '160px', marginTop: '20px' }}>
          登録申請
        </button>
      </div>

      <div style={{ width: '33%', padding: '20px' }}>
        {/* デバイス選択の追加 */}
        <div style={{ marginBottom: '20px' }}>
          <h3>デバイス選択</h3>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '20px' }}>
            {Object.keys(selectedDevices).map((device) => (
              <label key={device} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <input
                  type="checkbox"
                  checked={selectedDevices[device]}
                  onChange={() => handleDeviceChange(device)}
                />
                {device}
              </label>
            ))}
          </div>
        </div>

        <h3>コメント</h3>
        <div style={{ height: '400px', overflowY: 'auto', border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
          {comments.length > 0 ? (
            comments.map((comment) => (
              <div key={comment.id} style={{ marginBottom: '10px' }}>
                <p style={{ marginBottom: '5px' }}>{comment.text}</p>
                <p style={{ fontSize: '12px', color: 'gray' }}>{new Date(comment.createdAt.seconds * 1000).toLocaleString()}</p>
                <hr />
              </div>
            ))
          ) : (
            <p>コメントはありません。</p>
          )}
        </div>

        <textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="コメントを入力"
          rows="3"
          style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
        />
        <button 
          onClick={handleAddComment} 
          className="login-button" 
          style={{ width: '100%', padding: '10px' }}>
          コメントを追加
        </button>
      </div>
    </div>
  );
}

UpdateNoteForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func,
  updateNoteId: PropTypes.string,
};

UpdateNoteForm.defaultProps = {
  updateNoteId: null,
};

export default UpdateNoteForm;